import { createWebHistory, createRouter } from 'vue-router'
import OldBrowserView from "./components/OldBrowserView.vue"
import HomeView from "./components/HomeView.vue"

const routes = [
  { path: '/', component: HomeView },
  { path: '/old-browser', component: OldBrowserView }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router