<template>
    <div class="hello">
      home
    </div>
  </template>
  
  <script>
  export default {
    name: 'OldbroswerScreen'
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>

  </style>
  