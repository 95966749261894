<template>
  <div class="page">
    <div class="wrapper">
      <span class="title">Versão do Navegador Sankhya não suportada!</span>
      <span class="subtitle">
        Atualize a versão do seu navegador Sankhya ou acesse via Google Chrome para utilizar.
      </span>
      <!-- <div class="link">
        <img src="@/assets/chrome_logo.svg" height="24" width="24" />
        <a @click="downloadBrowser"
          >Clique aqui para fazer o download da versão mais atualizada.</a
        >
      </div> -->
    </div>
    <img src="@/assets/warning.svg" class="warning-img" />
  </div>
</template>

<script>
export default {
  name: "OldbroswerScreen",
  methods: {
    downloadBrowser() {
      window.open("https://www.google.com/intl/pt-BR/chrome/", "_blank");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.link {
  display: flex;
  align-items: center;
  gap: 8px;
  a {
    color: var(--gray-20500, #5d6585);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 142.857% */
    text-decoration-line: underline;
    cursor: pointer;
  }
}

.page {
  display: flex;
  align-items: center;
  height: 100vh;
  justify-content: center;
  gap: 88px;
}

.warning-img {
  height: 70vh;
}

.wrapper {
  display: flex;
  width: 700px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.title {
  color: #000;

  /* Display xl/Semibold */
  font-family: Inter;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  line-height: 72px; /* 120% */
  letter-spacing: -1.2px;
}

.subtitle {
  color: var(--gray-20500, #5d6585);

  /* Text xl/Normal */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 150% */
}
</style>
